/*! Datatrain Framework UI v#{VERSION_REPLACE}# */


var datatrainUi = angular.module('datatrain.ui', ['datatrain.ui.formItems', 'angular-chartist', 'uuid', 'ngDialog', 'duScroll']);

datatrainUi.value('versionNumber', '#{VERSION_REPLACE}');


// Timepicker defaults
angular.module('ui.timepicker').value('uiTimepickerConfig',{
    step: 30,
    timeFormat: 'H:i',
    scrollDefault: '08:00'
});


(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('blankslate/blankslate.html',
    '<div class="blankslate">\n' +
    '    <div class="icon"><i class="{{ icon }} icon-only"></i></div>\n' +
    '    <div ng-if="heading" class="heading" ng-bind="heading"></div>\n' +
    '    <div class="content padding-all" ng-transclude></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Blankslate
 */


angular.module('datatrain.ui')
	.directive('blankslate', function() {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			icon: '@',
			heading: '@',
			maxWidth : '@'
		},
		templateUrl: 'blankslate/blankslate.html',
		transclude: true,
		compile : function(tElem, tAttrs) {
			if ( tAttrs.maxWidth ) {
				tElem.find('.heading').css('maxWidth', tAttrs.maxWidth);
				tElem.find('.content').css('maxWidth', tAttrs.maxWidth);
			} 
			return {
				pre : function(scope, iELem, iAttrs) {
					if ( !scope.icon ) {
						scope.icon = 'ui-icon-info';
					}
					if ( iAttrs.title !== undefined && scope.heading === undefined ) {
						scope.heading = iAttrs.title;
						console.log('Blankslate ‘' + iAttrs.title + '’ utilises title attribute. Please replace title with heading!')
					}
				}
			};
		}
	};
});

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('chart/chart.html',
    '<figure class="chart">\n' +
    '  <div class="content padding-all">\n' +
    '    <h6 class="heading" ng-bind="heading"></h6>\n' +
    '    <div class="wrapper" ng-transclude></div>\n' +
    '  </div>\n' +
    '</figure>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Chart
 *
*/

angular.module('datatrain.ui')
//  .controller('chartCtrl', ['$scope', '$attrs', '$element', '$compile', 'rfc4122', function($scope, $attrs, $element, $compile, rfc4122) {
//    this.init = function(element) {
//      self.$element = element;
//      // Generate a UUID for input ID to link labels with input 
//      $scope.uuid = rfc4122.v4();
//    };
//  }])

  .directive('chart', ['rfc4122', function(rfc4122) {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        heading: '@',
      },
      templateUrl: 'chart/chart.html',
      transclude: true,
//      compile: function (element, attrs) {
      link: function(scope, element, attrs) {
          var uuid = rfc4122.v4();
          if (element.find('[caption]').length) {
            element.prepend('<input type="checkbox" id="chart-' + uuid + '-toggle" hidden />')
            element.find('[ng-transclude]').before('<label for="chart-' + uuid + '-toggle" class="button transparent round"><i class="ui-icon-info"></i></label>');
            element.find('[caption]').appendTo(element).end().wrap('<figcaption class="padding-all reverse"></figcaption').before('<h6 class="heading">' + scope.heading + '</h6><label for="chart-' + uuid + '-toggle" class="button transparent round"><i class="ui-icon-close"></i></label>');
          }
                
      }
    };
  }])
;
(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dialogs/dialog-tester.html',
    '<div class="modal-header">\n' +
    '      Beschreibung\n' +
    '</div>\n' +
    '<div class="modal-content">\n' +
    '      <div>\n' +
    '           \n' +
    '            <div form-item-select>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '              <option>Test</option>\n' +
    '            </div>\n' +
    '        \n' +
    '            <div form-item-date></div>\n' +
    '        \n' +
    '        </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '      <div class="button-group ">\n' +
    '            <a class="button" ng-click="closeThisDialog()">Schließen</a>\n' +
    '      </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dropdown/dropdown.html',
    '<div class="dropdown">\n' +
    ' 	<input id="{{ uuid }}-toggle" type="checkbox" hidden ng-disabled="isDisabled">\n' +
    '	<label class="button" for="{{ uuid }}-toggle" ng-disabled="isDisabled">\n' +
    '		{{ label }} <span class="icon {{ icon }}"></span>\n' +
    '	</label>\n' +
    '  	<div class="content toggle-show" ng-transclude></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Dropdown
 */


angular.module('datatrain.ui')
  .controller('dropdownCtrl', ['$scope', '$attrs', '$element', '$compile', 'rfc4122', function($scope, $attrs, $element, $compile, rfc4122) {
    this.init = function(element) {
      self.$element = element;
      // Generate a UUID for input ID to link labels with input 
      $scope.uuid = rfc4122.v4();
    };
    
    $attrs.$observe('disabled', function() {
        $scope.isDisabled = false;
        if (($attrs.disabled)) { // || (angular.isString($attrs.disabled) && ($attrs.disabled == '' || $attrs.disabled == 'disabled'))) {
          $scope.isDisabled = true;
        }
      });
  }])
  // Dropdown
  .directive('dropdown', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'dropdownCtrl',
      scope: {
        icon: '@',
        label: '@',
        orientation: '@',
        disabled : '@'
      },
      transclude: true,
      templateUrl: 'dropdown/dropdown.html',
      compile : function(tElem, tAttrs) {
    	  return {
    		  pre : function(scope, iElem, iAttrs, dropdownCtrl) {
    			  dropdownCtrl.init(iElem);
    			  if ( !scope.icon ) {
    				  scope.icon = 'ui-icon-open-down';
    				  
    			  } else {
    				  scope.icon = scope.icon + ' no-animation';
    			  }
   				  scope.isDisabled = iAttrs.disabled ? true : false;
    		  }
		  };
	  }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('progress/bar.html',
    '<div class="item float-left border-color-{{ color }}" ng-style="{width: (percent < 100 ? percent : 100) + \'%\'}" ng-transclude></div>');
}]);
})();

/**
 * Datatrain Framework UI Form Items Controller
*/

var datatrainUiFormItems = angular.module('datatrain.ui.formItems', ['720kb.datepicker', 'ui.timepicker', 'naif.base64', 'ui.utils.masks'])
  .controller('formItemsCtrl', ['$scope', '$attrs', '$element', '$compile', 'rfc4122', function($scope, $attrs, $element, $compile, rfc4122) {
    var self = this;
    
    this.createUuid = function() {
    	return rfc4122.v4(); 
    };
    
    
    this.init = function(element) {
      self.$element = element;
      // Generate a UUID for input ID to link labels with input
      if ( !$scope.uuid ) {
    	  $scope.uuid = rfc4122.v4();
      }
      $scope.isDisabled = angular.isDefined($attrs.disabled);
      $scope.isRequired = angular.isDefined($attrs.required);
      if ( !$scope.model && $scope.defLow ) {
    	  $scope.model = $scope.defLow;
      } else if ( typeof $scope.model === 'object' && ( $scope.defLow || $scope.defHigh ) ) {
    	  $scope.model.fieldvalue_low = $scope.model.fieldvalue_low || $scope.defLow;
    	  $scope.model.fieldvalue_high = $scope.model.fieldvalue_high || $scope.defHigh;
      }
    };
    
    this.resetDateModel = function(attrName) {
    	if ($scope.isDisabled) return false;
    	$scope.model[attrName] = undefined;
    	if ( attrName === 'fieldvalue_high' ) {
    		$scope.dateStringHigh = '';
    	} else {
    		$scope.dateString = '';
    		$scope.dateStringLow = '';
    	}
    };
    
    // Observe any changes on disabled property/attribute
    $attrs.$observe('disabled', function() {
      $scope.isDisabled = false;
      if (($attrs.disabled)) { // || (angular.isString($attrs.disabled) && ($attrs.disabled == '' || $attrs.disabled == 'disabled'))) {
        $scope.isDisabled = true;
      }
    });

    // Observe any changes on required property/attribute
    $attrs.$observe('required', function() {
      $scope.isRequired = false;
      
      if (($attrs.required)) { //|| (angular.isString($attrs.required) && ($attrs.required == '' || $attrs.required == 'required'))) {
        $scope.isRequired = true;
        
        if (angular.isDefined($attrs.placeholder) && angular.isString($attrs.placeholder) && $attrs.placeholder != '') {
          $scope.placeholder = $attrs.placeholder + ' (Pflichtfeld)';
        } else {
          $scope.placeholder = 'Pflichtfeld';
        }
      }
    });

    // Observe any changes on message attribute
    $attrs.$observe('message', function(message) {
      if ($attrs.message.length) {
//        var classes = ['danger', 'warning', 'success', 'info'];
        
        var insert = $element.find('.element').after('<div class="message danger">' + message + '</div>');
//        for (var i in classes) {
//           if ($element[0].classList.contains(classes[i])) {
//             $element.find('.message').addClass(classes[i]);
//           }
//        }
        $compile(insert)($scope);
      } else {
        $element.find('.message').remove();
      }
    });
    
    if ($scope.changeFunction) {
      $scope.$watch('model', function(newValue, oldValue) {
    	  if ( newValue !== oldValue ) {
    		  $scope.changeFunction();
    	  }
      });
    }
    
    
    $scope.scrollDown = function(event) {
    	var modalContent = angular.element(event.currentTarget).closest('.modal-content');
    	modalContent.animate({
          	scrollTop: modalContent.offset().top + 200
        });
    };
    
    this.getProperty = function(propName) {
    	return $scope[propName];
    };
    
    this.setProperty = function(propName, propVal) {
    	$scope[propName] = propVal;
    };
  }])
;
(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('progress/progress.html',
    '<div class="progress" ng-class="type">\n' +
    '  <div class="items clear background-color-{{ color }}" ng-transclude></div>\n' +
    '</div>');
}]);
})();


/**
 * Datatrain Framework UI Minitable
 *
*/

angular.module('datatrain.ui')
  // The tabs wrapper
  .directive('minitable', function() {
    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
      },
      template: '<div class="minitable" ng-transclude></div>'
    }
  })
  // The individual tab
  .directive('minitableItem', function() {
    return {
      restrict: 'A',
      transclude: true,
      template: '<div class="item"><div class="heading" ng-bind="heading"></div><div class="content" ng-transclude></div></div>',
      replace: true,
      require: '^minitable',
      scope: {
        heading: '@'
      }
    }
  });

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('prune/prune.html',
    '<div class="prune">\n' +
    '  <input id="prune-{{ uuid }}-toggle" type="checkbox" hidden>\n' +
    '  <div class="content" ng-transclude></div>\n' +
    '  <label for="prune-{{ uuid }}-toggle" class="inline-block">\n' +
    '    <span class="open ui-icon-open-down"></span>\n' +
    '    <span class="closed ui-icon-open-up"></span>\n' +
    '  </label>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI navTree
 *
*/
angular.module('datatrain.ui')
  // navTree
  .directive('navTree', function () {
    'use strict';
    return {
      restrict: 'A',
      replace: true,
      scope: {
        model: '='
      },
      template: '<ul class="menu"><li nav-tree-item ng-repeat="item in model" item="item"></li></ul>',
      link: function (scope, element, attrs) {
        if (!attrs.submenu) {
          element.wrap('<nav></nav>');
        }
      }
    }
  })
  // navTreeItem
  .directive('navTreeItem', ['$compile', function ($compile) {
    'use strict';
    return {
      restrict: 'A',
      replace: true,
      scope: {
        item: '='
      },
      template: '<li ng-class="[{\'active\': item.active, \'active-trail\': item.active_trail}]"></li>',
      link: function (scope, element, attrs) {
        scope.item.active_trail_string = (scope.item.active_trail) ? 'X' : '';

        if (angular.isArray(scope.item.children)) {
          element.append(
            '<div form-item-checkbox label="{{item.title}}" model="item.active_trail_string" icon-unchecked="ui-icon-open-right" icon-checked="ui-icon-open-down"></div><div nav-tree model="item.children" submenu="true"></div>'
          );
          $compile(element.contents())(scope);
        } else {
          element.append(
            '<a href="{{item.link}}" class="block" ng-class="[{\'active\': item.active}]">{{item.title}}</a>'
          );
          $compile(element.contents())(scope);
        }

        scope.$watch('item.active_trail_string', function() {
          scope.item.active_trail = (scope.item.active_trail_string == 'X') ? true : false;
        });
      }
    }
  }])
;
(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('tabs/tabs.html',
    '<div class="tabs" role="tabpanel">\n' +
    '  <div class="header" role="tablist">\n' +
    '    <div class="items" ng-class="{\'paginated\' : tabset.shouldPaginate}">\n' +
    '      <div class="item" role="presentation" ng-repeat="tab in tabset.tabs" ng-class="{active:tab.active}">\n' +
    '        <a href="" role="tab" ng-click="tabset.select(tab)">{{tab.heading}}</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <a href="" class="pager paginate-previous" ng-show="tabset.shouldPaginate" ng-class="{\'disabled\': !tabset.canPageToPrevious}" ng-click="tabset.scrollToPrevious()"><i class="ui-icon-open-left"></i></a>\n' +
    '    <a href="" class="pager paginate-next" ng-show="tabset.shouldPaginate" ng-class="{\'disabled\': !tabset.canPageToNext}" ng-click="tabset.scrollToNext()"><i class="ui-icon-open-right"></i></a>\n' +
    '  </div>   \n' +
    '  <div class="items" ng-transclude></div>\n' +
    '</div>');
}]);
})();


/**
 * Datatrain Framework UI Progress
 *
*/

angular.module('datatrain.ui')

.constant('progressConfig', {
  max: 100
})

.controller('progressController', ['$scope', '$attrs', 'progressConfig', function($scope, $attrs, progressConfig) {
  var self = this;
  self.items = [];
  
  self.addItem = function(item, element, attrs) {
    self.items.push(item);

    item.max = getMaxOrDefault();

    item.$watch('value', function(value) {
      item.calcWidth();
    });

    item.calcWidth = function() {
      var totalPercentage = self.items.reduce(function(total, item) {
        item.percent = +(100 * item.value / item.max).toFixed(2);
        return total + item.percent;
      }, 0);
    };

    item.$on('$destroy', function() {
      element = null;
      self.removeItem(item);
    });
  };

  self.removeItem = function(item) {
    self.items.splice(self.items.indexOf(item), 1);
    self.items.forEach(function (item) {
      item.calcWidth();
    });
  };
  
  $scope.$watch('maxParam', function(maxParam) {
    self.items.forEach(function(item) {
      item.max = getMaxOrDefault();
      item.calcWidth();
    });
  });

  function getMaxOrDefault () {
    return angular.isDefined($scope.maxParam) ? $scope.maxParam : progressConfig.max;
  }  
}])

.directive('progress', function() {
  return {
    replace: true,
    transclude: true,
    controller: 'progressController',
    scope: {
      color: '@',
      maxParam: '=?max',
      type: '@'
    },
    templateUrl: 'progress/progress.html'
  };
})

.directive('progressItem', function() {
  return {
    replace: true,
    transclude: true,
    require: '^progress',
    scope: {
      color: '@',
      value: '='
    },
    templateUrl: 'progress/bar.html',
    link: function(scope, element, attrs, progressCtrl) {
      progressCtrl.addItem(scope, element, attrs);
    }
  };
});


(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('buttons/splitbutton/splitbutton.html',
    '<div class="splitbutton">\n' +
    '  <input id="{{ uuid }}-toggle" type="checkbox" hidden>\n' +
    '  <button ng-click="primaryFunction()">{{ label }}</button>\n' +
    '  <label class="button" for="{{ uuid }}-toggle"><span class="icon ui-icon-open-down"></span></label>\n' +
    '  <div class="content toggle-show" ng-transclude></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Prune
 *
*/

angular.module('datatrain.ui')
  .controller('pruneCtrl', ['$scope', '$attrs', '$element', '$compile', 'rfc4122', function($scope, $attrs, $element, $compile, rfc4122) {
    this.init = function(element) {
      self.$element = element;
      // Generate a UUID for input ID to link labels with input 
      $scope.uuid = rfc4122.v4();
    };
  }])
  .directive('prune', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'pruneCtrl',
      templateUrl: 'prune/prune.html',
      transclude: true,
      link: function(scope, element, attrs, pruneCtrl) {
        pruneCtrl.init(element);
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-autocomplete/form-item-autocomplete.html',
    '<div class="form-item-autocomplete">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">    \n' +
    '    <label prefix for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <input maxlength="{{maxlength}}" id="form-item-{{ uuid }}" type="text" ng-model="model" placeholder="{{ placeholder }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-change="output = \'\'" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '    <div class="icon"><button ng-click="addonFunction()" ng-disabled="isDisabled"><i class="{{addonIcon || \'ui-icon-tree-list\'}}"></i></button></div>\n' +
    '    <label suffix ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div class="output" ng-transclude ng-show="!output && model.length">\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();


/**
 * Datatrain Framework UI Tabs
 * Based on https://thinkster.io/angular-tabs-directive
 *
*/

angular.module('datatrain.ui')
  // The tabs controller
  .controller('tabsCtrl', ['$scope', '$window', '$timeout', function($scope, $window, $timeout) {
        var self = this, 
        	wndw = angular.element($window),
        	destroyed;
        // Create elements and tabs list
        self.elements = $scope.elements;
        self.defineElements =  function(element) {
        	self.elements = {
        		headerItems : element.find('.header>.items:eq(0)'),
            	tabByIndex : function (tabIndex) {
            		return element.find('.header>.items>.item:eq(' + tabIndex +  ')');
            	}
        	};
        };
        
        self.tabs = $scope.tabs = [];
        self.shouldPaginate = self.canPageToPrevious = self.canPageToNext = false;
        
        // Add tab to tabs list
        self.addTab = function addTab(tab) {
          self.tabs.push(tab);

          // Set first tab active  
          if(self.tabs.length === 1) {
            tab.active = true;
          }
        };
            
        // Select tab 
        self.select = function(selectedTab) {
        	// Loop all tabs in tabs list
        	angular.forEach(self.tabs, function(tab) {
        		// Not selected tab is not active
        		if(tab.active && tab !== selectedTab) {
        			tab.active = false;
        		}
        	});
        	
        	// Set selected tab active
        	selectedTab.active = true;
        }; 

        // Returns the active tab
        self.getSelectedTab = function() {
        	for ( var t in self.tabs ) {
        		if ( self.tabs[t].active ) {
        			return self.tabs[t];
        		}
        	}        	
        };
        
        // Returns the index of the first tab that is completely visible
        self.getFirstVisibleTabIndex = function() {
        	var headerItems = self.elements.headerItems;
        	for ( var t = 0; t < self.tabs.length; t++ ) {
        		var elem = self.elements.tabByIndex(t);
        		// Tab is visible if its left offset is greater or equal to the left scroll of its container
    			if ( elem[0].offsetLeft >= headerItems[0].scrollLeft ) {
        			return t*1;
        		}
        	}
        };
        
        // Remove tab
        self.removeTab = function removeTab(tab) {
        	var index = self.tabs.indexOf(tab);
        	// Select a new tab if the tab to be removed is selected and not destroyed
        	if ( tab.active && self.tabs.length > 1 && !destroyed ) {
        		// If this is the last tab, select the previous tab, else, the next tab
        		var newActiveIndex = ( index === self.tabs.length - 1 ) ? index - 1 : index + 1;
        		self.select(self.tabs[newActiveIndex]);
        	}
        	self.tabs.splice(index,1);
        };
        
        // Scrolls left by 1 tab        
        self.scrollToPrevious = function scrollToPrevious() {
        	self.scroll(-1).then(function() {
        		self.updatePageDirections();
        	});
        };
        
        // Scrolls right by 1 tab
        self.scrollToNext = function scrollToNext() {
        	self.scroll(1).then(function() {
        		self.updatePageDirections();
        	});
        };
        
        // Scrolls to @direction by 1 tab
        self.scroll = function(direction) {
        	var firstVisibleTabIndex = self.getFirstVisibleTabIndex();
        	var newFirstVisibleTab = self.elements.tabByIndex(firstVisibleTabIndex + direction);
        	var targetPos = newFirstVisibleTab[0].offsetLeft;
        	return self.elements.headerItems.duScrollLeftAnimated(targetPos);
        };
        
        self.scrollToActiveTab = function() {
        	var selectedTabIndex = self.tabs.indexOf(self.getSelectedTab());
        	var targetPos = self.elements.tabByIndex(selectedTabIndex)[0].offsetLeft;
        	return self.elements.headerItems.duScrollLeftAnimated(targetPos);
        };
        
        // Updates the ability to scroll left or right
        self.updatePageDirections = function() {
        	var firstVisibleTabIndex = self.getFirstVisibleTabIndex();
        	self.canPageToPrevious = 	!( firstVisibleTabIndex === 0 ); 
    		var lastTab = self.elements.tabByIndex(self.tabs.length - 1);
    		var firstVisibleTab = self.elements.tabByIndex(firstVisibleTabIndex);
    		var lastTabVisible =  self.elements.headerItems[0].scrollWidth - self.elements.headerItems[0].scrollLeft - self.elements.headerItems[0].clientWidth  > 0;
    		self.canPageToNext = lastTabVisible;
        };
        
        // Updates the ability to page 
        self.updateShouldPaginate = function() {
        	var clientWidth = self.elements.headerItems[0].clientWidth;
        	var scrollWidth = self.elements.headerItems[0].scrollWidth;
        	if ( clientWidth < scrollWidth ) {
        		self.shouldPaginate = true;
        		self.updatePageDirections();
        	} else {
        		self.shouldPaginate = false;
        	}
        };
        
        
        $scope.getHeaderItemsScrollWidth = function() {
        	return self.elements.headerItems[0].scrollWidth;
        };
        // Set destroyed on $destroy               
        $scope.$on('$destroy', function() {
        	destroyed = true;
        });
        
        $scope.$watch('getHeaderItemsScrollWidth()', function(newVal, oldVal) {
        	if ( newVal && newVal !== oldVal ) {
        		self.updateShouldPaginate();
        	}
        });
        
        // Bind ability to page to window resize event
        wndw.bind('resize', function() {
        	self.updateShouldPaginate();
        	$scope.$apply();
        	self.scrollToActiveTab();
        });
  }])
  // The tabs wrapper
  .directive('tabs', ['$timeout', function($timeout) {
    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
      },
      templateUrl: 'tabs/tabs.html',
      bindToController: true,
      controllerAs: 'tabset',
      controller: 'tabsCtrl',
      compile: function (tElem, tAttrs) {
//    	  console.log('tabs compile: ' + tElem.html());
          // If headingElement is defined wrap tab in element
          if ( tAttrs.headingElement !== undefined ) {
            tElem.find('.header [role="tab"]').wrap('<' + tAttrs.headingElement + '></' + tAttrs.headingElement + '>')
          }
          var tabHeaderItems = tElem.find('.header>.items');
          var tabs = tElem.find('.header>.items>.item');
          return {
        	  post : function(scope, iElem, iAttrs, tabsetCtrl) {
		       	  // console.log('tabs postlink: ' + iElem.html());
	        	  tabsetCtrl.defineElements(iElem);
    		  }
          };
      }
    };
  }])
  // The individual tab
  .directive('tab', function() {
    return {
      restrict: 'A',
      transclude: true,
      template: '<div class="item" role="tabpanel" ng-show="active" ng-transclude></div>',
      replace: true,
      require: '^tabs',
      scope: {
        heading: '@'
      },
      link: function(scope, element, attrs, tabsetCtrl) {
        scope.active = false;
        tabsetCtrl.addTab(scope);
        
        scope.$on('$destroy',  function() {
        	tabsetCtrl.removeTab(scope);
        });
      }
    };
  });

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-checkbox/form-item-checkbox.html',
    '<div class="form-item-checkbox">\n' +
    '	<div class="element">\n' +
    '    	<input id="form-item-{{ uuid }}" type="checkbox" ng-model="model" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" ng-true-value="\'X\'" ng-false-value="\'\'" hidden />\n' +
    '    	<label for="form-item-{{ uuid }}"><i class="icon" ng-class="model == \'X\' ? iconChecked : iconUnchecked "></i> <span ng-bind="label"></span></label>\n' +
    '	</div>\n' +
    '  	<div ng-bind="description" class="description"></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Split Button
 */


angular.module('datatrain.ui')
  // Dropdown
  .directive('splitbutton', function() {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        label: '@',
        primaryFunction: '&',
        orientation: '@'
      },
      transclude: true,
      templateUrl: 'buttons/splitbutton/splitbutton.html',
      link: function(scope, element, attrs) {
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-date/form-item-date.html',
    '<div class="form-item-date">\n' +
    '	<label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '    <div datepicker date-format="{{ format }}" date-set="{{ model.fieldvalue_low.toString() }}" date-model="fieldvalue_low" class="element">\n' +
    '		<input id="form-item-{{ uuid }}" type="text" ng-model="dateString" placeholder="{{ placeholder }}" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" readonly />\n' +
    '		<label for="form-item-{{ uuid }}" class="icon" aria-hidden="true" ng-class="{\'clickable\' : !isDisabled && model.fieldvalue_low}" \n' +
    '       ng-disabled="isDisabled" ng-click="formItemsCtrl.resetDateModel(\'fieldvalue_low\')">\n' +
    '      		<span ng-class="model.fieldvalue_low ? \'ui-icon-close\' : \'ui-icon-calendar\'"></span>\n' +
    '    	</label>\n' +
    '    </div>\n' +
    '    <div ng-bind="description" class="description" ></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Form Item Autocomplete
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemAutocomplete', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        label: '@',
        addonIcon : '@',
        model: '=',
        maxlength : '@',
        defLow : '@',
        output: '=?',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@',
        addonFunction: '&'
      },
      transclude: true,
      templateUrl: 'form-items/form-item-autocomplete/form-item-autocomplete.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) {
    		  tElem.find('label[ng-bind="label"]').remove();
    	  }
    	  if ( !tAttrs.prefix && !tAttrs.prefixClass ) {
    		  tElem.find('[prefix]').remove();
    	  } 
    	  if ( !tAttrs.suffix && !tAttrs.suffixClass ) {
    		  tElem.find('[suffix]').remove();
    	  } 
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  tElem.find('[prefix]').removeAttr('prefix');
    	  tElem.find('[suffix]').removeAttr('suffix');
    	  // Remove the addon button if no addon function is specified
    	  if ( !tAttrs.addonFunction ) tElem.find('.icon').remove();
    	  if ( !tAttrs.icon  && tAttrs.addonFunction ) console.log('No addon-icon for autocomplete specified. Note, that the default \"ui-icon-tree-list\" will deprecate and the form-item-autocomplete will expect the required icon within the \"addon-icon\" attribute');
    	  return {
    		  post : function(scope, element, attrs, formItemsCtrl) {
    			  formItemsCtrl.init(element);    
    			  scope.selectItem = function(option) {
    				  scope.model = option;
    				  scope.focus = false;
    			  };
    		  }
    	  };
      }
    };
  });

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-daterange/form-item-daterange.html',
    '<div class="form-item-daterange">\n' +
    '	<label ng-bind="label"></label>\n' +
    '   	<div class="element">\n' +
    '     		<div class="form-item-date">\n' +
    '         		<div datepicker date-format="{{ format }}" date-set="{{ model.fieldvalue_low.toString() }}" date-model="fieldvalue_low" class="element">\n' +
    '           		<input id="form-item-{{ uuid }}-low" type="text" ng-model="dateStringLow" placeholder="{{ placeholder }}" ng-disabled="isDisabled" ng-required="isRequired" readonly />\n' +
    '           		<label for="form-item-{{ uuid }}-low" class="icon" aria-hidden="true" ng-class="{\'clickable\' : !isDisabled && model.fieldvalue_low}"\n' +
    '           		ng-disabled="isDisabled" ng-click="formItemsCtrl.resetDateModel(\'fieldvalue_low\')">\n' +
    '           			<span ng-class="model.fieldvalue_low ? \'ui-icon-close\' : \'ui-icon-calendar\'"></span>\n' +
    '       			</label>\n' +
    '         		</div>\n' +
    '     		</div>\n' +
    '      	<div class="addon">bis</div>\n' +
    '      	<div class="form-item-date">\n' +
    '			<div datepicker date-format="{{ format }}" date-set="{{ model.fieldvalue_high.toString() }}" date-model="fieldvalue_high" class="element">\n' +
    '				<input id="form-item-{{ uuid }}-high" type="text" ng-model="dateStringHigh" placeholder="{{ placeholder }}" ng-disabled="isDisabled" ng-required="isRequired" readonly />\n' +
    '	            <label for="form-item-{{ uuid }}-high" class="icon" aria-hidden="true" ng-class="{\'clickable\' : !isDisabled && model.fieldvalue_high}"\n' +
    '	            ng-disabled="isDisabled" ng-click="formItemsCtrl.resetDateModel(\'fieldvalue_high\')">\n' +
    '	            	<span ng-class="model.fieldvalue_high ? \'ui-icon-close\' : \'ui-icon-calendar\'"></span>\n' +
    '	           	</label>\n' +
    '         	</div>\n' +
    '      	</div>\n' +
    '	</div>\n' +
    '    <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Checkbox
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemCheckbox', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        defLow : '@',
        label: '@',
        model: '=',
        iconChecked: '@',
        iconUnchecked: '@',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-checkbox/form-item-checkbox.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.description ) tElem.find('.description').remove(); 
    	  return {
    		  post: function(scope, element, attrs, formItemsCtrl) {
    			  formItemsCtrl.init(element);
    			  
    			  scope.iconChecked = (angular.isDefined(scope.iconChecked)) ? scope.iconChecked : 'ui-icon-checkbox-checked';
    			  scope.iconUnchecked = (angular.isDefined(scope.iconUnchecked)) ? scope.iconUnchecked : 'ui-icon-checkbox';
    		  }
    	  };
      }
    };
  });

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-file/form-item-file.html',
    '<div class="form-item-file">\n' +
    '	<div class="element" element-type="button">\n' +
    '		<label for="form-item-{{ uuid }}" class="button no-margin"><span ng-bind="label"></span></label> \n' +
    '		<input id="form-item-{{ uuid }}" type="file" ng-model="model" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" base-sixty-four-input onload="changeFunction()" />\n' +
    '	</div>\n' +
    '	<div class="element block padding-all-half" element-type=\'default\'>\n' +
    '		<label for="form-item-{{ uuid }}">\n' +
    '			<div class="text-center text-gray" style="font-size: 1.5em"><i class="ui-icon-upload"></i></div>\n' +
    '			<div ng-bind="label" class="text-center text-gray text-bold"></div>\n' +
    '			<p class="text-center text-gray text-smaller">\n' +
    '				Bitte klicken Sie dieses Feld, um eine Datei auszuwählen.\n' +
    '			</p>\n' +
    '		</label>\n' +
    '		<input id="form-item-{{ uuid }}" type="file" ng-model="model" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" base-sixty-four-input onload="changeFunction()" />\n' +
    '	</div>\n' +
    '	<div ng-bind="description" class="description" ></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Form date
 *

*/


angular.module('datatrain.ui.formItems')
  .directive('formItemDate', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      controllerAs : 'formItemsCtrl',
      scope: {
        format: '@',
        description: '@',
        label: '@',
        defLow : '=',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-date/form-item-date.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  return {
    		  post: function(scope, element, attrs, formItemsCtrl) {
    			  formItemsCtrl.init(element);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-number/form-item-number.html',
    '<div class="form-item-number">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <input id="form-item-{{ uuid }}" type="text" ng-model="model" placeholder="{{ placeholder }}" ui-number-mask="{{ mask }}" ui-negative-number maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '    <label for="form-item-{{ uuid }}" class="icon" aria-hidden="true"><span class="{{ icon }}"></span></label>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form date
 *

*/


angular.module('datatrain.ui.formItems')
  .directive('formItemDaterange', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      controllerAs : 'formItemsCtrl',
      scope: {
        format: '@',
        description: '@',
        divider: '@',
        label: '@',
        defLow : '=',
        defHigh : '=',
        model: '=',
        placeholderLow: '@',
        placeholderHigh: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-daterange/form-item-daterange.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  return {
		      post : function(scope, iElem, iAttrs, formItemsCtrl) {
			        formItemsCtrl.init(iElem);
		      }
	      };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-output/form-item-output.html',
    '<div class="form-item-output">\n' +
    '  <label ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <div class="static" ng-transclude></div>\n' +
    '    <div  class="icon"><button class="{{ icon }}" ng-click="iconFunction()"></button></div>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item File
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemFile', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        type : '@',
        model: '=',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-file/form-item-file.html',
      compile : function(tElem, tAttrs) {
    	  if ( tAttrs.type  === 'button' ) {
    		  tElem.find('[element-type="default"]').remove();
    	  } else {
    		  tElem.find('[element-type="button"]').remove();
    	  }
    	  tElem.find('[element-type]').removeAttr('element-type');    	  
    	  if ( !tAttrs.description ) tElem.find('.description').remove();

    	  return {
    		  post : function(scope, element, attrs, formItemsCtrl) {
    			  formItemsCtrl.init(element);    				
    		  }
    	  };
      },
    };
  });
/**
 * Datatrain Framework UI Form Item Time
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemNumber', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        maxlength: '@',
        defLow : '@',
        mask: '@',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-number/form-item-number.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.icon ) tElem.find('.icon').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-password/form-item-password.html',
    '<div class="form-item-password">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <input id="form-item-{{ uuid }}" type="password" ng-model="model" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '    <label for="form-item-{{ uuid }}" class="icon" aria-hidden="true"><span class="{{ icon }}"></span></label>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-radio/form-item-radio.html',
    '<div class="form-item-radio">\n' +
    '	<div class="element">\n' +
    '		<input id="form-item-{{ ::uuid }}" type="radio" name="form-item-{{name}}" ng-model="checked" ng-value="value" aria-labelledby="form-item-{{ ::uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" hidden /> \n' +
    '		<label for="form-item-{{ ::uuid }}">\n' +
    '<!-- 			<i class="icon" ng-class="[{\'ui-icon-radio-checked\' : getInputChecked()}, {\'ui-icon-radio\' : !getInputChecked()}]"></i> -->\n' +
    '			<i class="icon" ng-class="[{\'ui-icon-radio-checked\' : formItemsCtrl.getProperty(\'model\') == value}, {\'ui-icon-radio\' : formItemsCtrl.getProperty(\'model\') !== value}]"></i>\n' +
    '			<span ng-bind="label"></span>\n' +
    '		</label>\n' +
    '	</div>\n' +
    '  	<div ng-bind="description" class="description"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Text
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemOutput', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        iconFunction: '&',
        label: '@',
        model: '=',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
        
      },
      templateUrl: 'form-items/form-item-output/form-item-output.html',
      transclude: true,
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.icon ) tElem.find('.icon').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix && !tAttrs.suffixClass ) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-radio/form-item-radios.html',
    '<div class="form-item-radios" >\n' +
    '  	<label ng-bind="label"></label>\n' +
    '  	<div class="element" ng-transclude></div>\n' +
    '  	<div ng-bind="description" class="description"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Password
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemPassword', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        maxlength: '@',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-password/form-item-password.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.icon ) tElem.find('.icon').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-rating/form-item-rating.html',
    '<div class="form-item-rating">\n' +
    '  <label ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <input ng-repeat-start="step in range(steps) | orderBy:\'-\'" id="form-item-{{ uuid }}-{{step}}" type="radio" name="form-item-{{ uuid }}" ng-model="$parent.model" value="{{ step }}" />\n' +
    '    <label ng-repeat-end for="form-item-{{ uuid }}-{{step}}"><i class="ui-icon-star-filled"></i></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>');
}]);
})();

/**
 * Datatrain Framework UI Form Item Radios
 *
*/

datatrainUiFormItems.directive('formItemRadios', function() {
	return {
		restrict : 'A',
		replace : true,
		transclude : true,
		controller : 'formItemsCtrl',
		scope : {
			model : '=',
			defLow : '@',
			label : '@',
			name : '@',
			description : '@',			
			changeFunction : '@'
		},
		templateUrl : 'form-items/form-item-radio/form-item-radios.html',
		compile : function(tElem, tAttrs) {
			if ( !tAttrs.label ) tElem.find('.label').remove();
			if ( !tAttrs.description ) tElem.find('.description').remove();
			return {
				post : function(scope, iElem, iAttrs, formItemsCtrl) {
					formItemsCtrl.init(iElem);
				} 
			}
		}
	};
});


var FormItemRadioController = ['$scope', '$element', function($scope, $element) {
	
	$scope.getInputChecked = function() {
		return $element.find('input').prop('checked')
	};
	
	$scope.input = $element.find('input');
	$scope.checked = $scope.input.prop('checked');
	
	
	$scope.$watch('getInputChecked()', function (newValue, oldValue) {
		if ( newValue ) {
			if ( $scope.formItemsCtrl ) {
				$scope.formItemsCtrl.setProperty('model', $scope.value);
			} 
		}
	})
}];

datatrainUiFormItems.directive('formItemRadio', function() {
	return {
		restrict : 'A',
		replace : true,
		controller : FormItemRadioController, 
		require : ['formItemRadio', '^formItemRadios'],
		scope : {
			label : '@',
			value : '=',
			name : '@',
			description : '@'
		},
		templateUrl : 'form-items/form-item-radio/form-item-radio.html',
		compile : function(tElem, tAttrs) {
			if ( !tAttrs.description ) tElem.find('.description').remove();
			return {
				pre : function(scope, iElem, iAttrs, ctrls) {
					var formItemsCtrl = ctrls[1];
					scope.name = scope.name ? scope.name : formItemsCtrl.getProperty('name') || formItemsCtrl.getProperty('uuid');
				},
				post : function(scope, iElem, iAttrs, ctrls) {
					var formItemsCtrl = ctrls[1];
					scope.formItemsCtrl = formItemsCtrl;
					if ( !scope.uuid ) {
						scope.uuid = formItemsCtrl.createUuid();
					}
				}
			};
		} 
	};
});
(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-select/form-item-select.html',
    '<div class="form-item-select">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <select id="form-item-{{ uuid }}" ng-model="model" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" ng-transclude>\n' +
    '    </select>\n' +
    '    <label for="form-item-{{ uuid }}" class="icon" aria-hidden="true"><span class="ui-icon-open-down"></span></label>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Text
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemRating', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        defLow : '@',
        model: '=',
        steps: '@',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-rating/form-item-rating.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  return {
    		  post: function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);    			  
    			  scope.range = function(count){
    				  var counts = [];
    				  for (var i = 1; i <= count; i++) { 
    					  counts.push(i); 
    				  } 
    				  return counts;
    			  }
    		  }
    	  };
      }
    };
  })
;

/**
 * Datatrain Framework UI Form Item Select
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemSelect', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        label: '@',
        model: '=',
        defLow : '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-select/form-item-select.html',
      transclude: true,
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-text/form-item-text.html',
    '<div class="form-item-text">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <input id="form-item-{{ uuid }}" type="text" ng-model="model" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '    <label ng-hide="enableClear && model.length" for="form-item-{{ uuid }}" class="icon" aria-hidden="true"><span class="{{ icon }}"></span></label>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Text
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemText', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        maxlength: '@',
        defLow : '@',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@',
        changeFunction: '&'
      },
      templateUrl: 'form-items/form-item-text/form-item-text.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.icon ) tElem.find('.icon').remove();
    	  if ( !tAttrs.prefix && !tAttrs.prefixClass ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix && !tAttrs.suffixClass) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-textarea/form-item-textarea.html',
    '<div class="form-item-textarea">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <textarea id="form-item-{{ uuid }}" ng-model="model" rows="{{ rows }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Textarea
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemTextarea', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        defLow : '@',
        model: '=',
        placeholder: '@',
        rows: '@',
        changeFunction : '&'
      },
      templateUrl: 'form-items/form-item-textarea/form-item-textarea.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-textrange/form-item-textrange.html',
    '<div class="form-item-textrange">\n' +
    '  <label for="form-item-{{ uuid }}-low" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}-low" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <div class="form-item-text">\n' +
    '      <div class="element">\n' +
    '        <input id="form-item-{{ uuid }}-low" type="text" ng-model="model.fieldvalue_low" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <label class="addon">bis</label>\n' +
    '    <div class="form-item-text">\n' +
    '      <div class="element">\n' +
    '        <input id="form-item-{{ uuid }}-high" type="text" ng-model="model.fieldvalue_high" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" ng-disabled="isDisabled" ng-required="isRequired" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}-high"  class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Text
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemTextrange', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        icon: '@',
        label: '@',
        maxlength: '@',
        defLow: '@',
        defHigh: '@',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-textrange/form-item-textrange.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-time/form-item-time.html',
    '<div class="form-item-time">\n' +
    '  <label for="form-item-{{ uuid }}" ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label for="form-item-{{ uuid }}" ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <input ui-timepicker id="form-item-{{ uuid }}" type="text" ng-model="model.fieldvalue_low" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" ng-focus="scrollDown($event)" />\n' +
    '    <label for="form-item-{{ uuid }}" class="icon" aria-hidden="true"><span class="ui-icon-time"></span></label>\n' +
    '    <label ng-bind="suffix" for="form-item-{{ uuid }}" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Time
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemTime', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        defLow : '=',
        label: '@',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-time/form-item-time.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    		  }
    	  };
      }
    };
  })
;

(function(module) {
try {
  module = angular.module('datatrain.ui');
} catch (e) {
  module = angular.module('datatrain.ui', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('form-items/form-item-timerange/form-item-timerange.html',
    '<div class="form-item-timerange">\n' +
    '  <label ng-bind="label"></label>\n' +
    '  <div class="element">\n' +
    '    <label ng-bind="prefix" class="addon {{ prefixClass }}"></label>\n' +
    '    <div class="form-item-time">\n' +
    '      <div class="element">\n' +
    '        <input ui-timepicker id="form-item-{{ uuid }}-low" type="text" ng-model="model.fieldvalue_low" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" ng-focus="scrollDown($event)" />\n' +
    '        <label for="form-item-{{ uuid }}-low" class="icon" aria-hidden="true"><span class="ui-icon-time"></span></label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="addon">bis</div>\n' +
    '    <div class="form-item-time">\n' +
    '      <div class="element">\n' +
    '        <input ui-timepicker id="form-item-{{ uuid }}-high" type="text" ng-model="model.fieldvalue_high" placeholder="{{ placeholder }}" maxlength="{{ maxlength }}" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-labelledby="form-item-{{ uuid }}-label" ng-disabled="isDisabled" ng-required="isRequired" ng-focus="scrollDown($event)" />\n' +
    '        <label for="form-item-{{ uuid }}-high" class="icon" aria-hidden="true"><span class="ui-icon-time"></span></label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <label ng-bind="suffix" class="addon {{ suffixClass }}"></label>\n' +
    '  </div>\n' +
    '  <div ng-bind="description" class="description" ></div>\n' +
    '</div>\n' +
    '');
}]);
})();

/**
 * Datatrain Framework UI Form Item Timerange
 *
*/

angular.module('datatrain.ui.formItems')
  .directive('formItemTimerange', function() {
    return {
      restrict: 'A',
      replace: true,
      controller: 'formItemsCtrl',
      scope: {
        description: '@',
        label: '@',
        defLow : '=',
        defHigh : '=',
        defRange : '=',
        model: '=',
        placeholder: '@',
        prefix: '@',
        prefixClass: '@',
        suffix: '@',
        suffixClass: '@'
      },
      templateUrl: 'form-items/form-item-timerange/form-item-timerange.html',
      compile : function(tElem, tAttrs) {
    	  if ( !tAttrs.label ) tElem.find('[ng-bind="label"]').remove();
    	  if ( !tAttrs.description ) tElem.find('.description').remove();
    	  if ( !tAttrs.prefix ) tElem.find('[ng-bind="prefix"]').remove();
    	  if ( !tAttrs.suffix) tElem.find('[ng-bind="suffix"]').remove();
    	  return {
    		  post : function(scope, iElem, iAttrs, formItemsCtrl) {
    			  formItemsCtrl.init(iElem);
    			  if ( scope.defRange ) {
    				  scope.$watch('model.fieldvalue_low', function(newVal, oldVal) {
    					  if ( newVal && newVal !== oldVal ) {
    						  // Set fieldvalue_high automatically if it is left empty or invalid
    						  if ( !scope.model.fieldvalue_high || newVal > scope.model.fieldvalue_high ) {
    							  var fieldvalue_high = new Date(newVal);
    							  fieldvalue_high.setHours(fieldvalue_high.getHours() + scope.defRange*1);
    							  if ( fieldvalue_high.getDay() > newVal.getDay() ) {
    								  fieldvalue_high = angular.copy(newVal);
    								  fieldvalue_high.setHours(23);
    								  fieldvalue_high.setMinutes(59);
    							  }
    							  scope.model.fieldvalue_high = fieldvalue_high;
    						  }
    					  }
    				  });
    				  scope.$watch('model.fieldvalue_high', function(newVal, oldVal) {
    					  if ( newVal && newVal !== oldVal ) {
    						  // Set fieldvalue_low automatically if it is left empty or invalid
    						  if ( !scope.model.fieldvalue_low || scope.model.fieldvalue_low > newVal ) {
    							  var fieldvalue_low = new Date(newVal);
    							  fieldvalue_low.setHours(fieldvalue_low.getHours() - scope.defRange*1);
    							  if ( fieldvalue_low.getDay() < newVal.getDay() ) {
    								  fieldvalue_low = angular.copy(newVal);
    								  fieldvalue_low.setHours(0);
    								  fieldvalue_low.setMinutes(0);
    							  }
    							  scope.model.fieldvalue_low = fieldvalue_low;
    						  }
    					  } 
    				  });
    			  }
    		  }
    	  };
      }
    };
  })
;
