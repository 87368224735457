Chartist.createSvg = function (container, width, height, className) {
	var svg;

	//Get the expected width and height of the viewBox
	//This could be cleaner
	var viewBoxWidth = width || container.clientWidth;
	var viewBoxHeight = height || container.clientHeight;
	width = width || '100%';
	height = height || '100%';
	
	// Check if there is a previous SVG element in the container that contains the Chartist XML namespace and remove it
	// Since the DOM API does not support namespaces we need to manually search the returned list http://www.w3.org/TR/selectors-api/
	Array.prototype.slice.call(container.querySelectorAll('svg')).filter(function filterChartistSvgObjects(svg) {
		return svg.getAttributeNS('http://www.w3.org/2000/xmlns/', Chartist.xmlNs.prefix);	
	}).forEach(function removePreviousElement(svg) {
		container.removeChild(svg);
	});

	// Create svg object with width and height or use 100% as default
	svg = new Chartist.Svg('svg').attr({
		width: width,
		height: height,
		//Set the viewbox attribute, this could be much cleaner
		viewBox: "0 0 " + viewBoxWidth + " " + viewBoxHeight
	}).addClass(className).attr({
		style: 'width: ' + width + '; height: ' + height + ';'
	});
	
	// Add the DOM node to our container
    container.appendChild(svg._node);
    return svg;
};
/**
 * Datatrain Framework
 * Polyfill for position: sticky;
 * Version: 1.0
 * 19 November 2015
 *
 * © Datatrain GmbH
 */

;(function(window, document) {
  var timeout = null;
  
  // Test browser for position: sticky support
  var positionStickyTest = document.createElement('div');
  positionStickyTest.style.cssText = 'position: ' + [ '-webkit-', '-moz-', '-ms-', '-o-', '' ].join( 'sticky' + ';' + 'position: ' ) + 'sticky' + ';';
  var positionStickySupported = positionStickyTest.style['position'].indexOf('sticky') !== -1;
  
  // Overrule positionStickySupported on Firefox due to partial support, only
  // (postion: sticky behaves like position relative on table elements)
  if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    positionStickySupported = false;
  }

  // Helper function to get all previous siblings
  function getPreviousSiblings(elem) {
    var siblings = [];
    while (elem = elem.previousSibling) {
      siblings.push(elem);
    }
    return siblings;
  }  
  
  function positionStickyPolyfill() {
    // Get all elements with sticky class
    var elements = document.getElementsByClassName('sticky');

    
    for (var i = 0; i < elements.length; i++) {
      // Get boundaries for element as well as parent
      var elementBoundaries = elements[i].getBoundingClientRect(),
          parentBoundaries = elements[i].parentElement.getBoundingClientRect(),
          parentComputedStyle = window.getComputedStyle(elements[i].parentElement, null),
          offset = 0,
          sibling = elements[i].parentNode.firstChild;
      
      
      offset += parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10);
      offset += parseInt(parentComputedStyle.getPropertyValue('margin-top'), 10);
      offset += parseInt(parentComputedStyle.getPropertyValue('padding-top'), 10);
      
      while(sibling && sibling !== elements[i]) {
        if (sibling.nodeType === 1) {
          offset += sibling.getBoundingClientRect().height;
        }
        sibling = sibling.nextElementSibling || sibling.nextSibling;
      }
      
      // Check if element is outside the viewport but parent is still inside, then hide the element
      if((parentBoundaries.top + offset) < 0 && (parentBoundaries.bottom - (elementBoundaries.height)) > 0) {
        elements[i].style.visibility = 'hidden';
        elements[i].style.position = 'relative';
        elements[i].style.opacity = 0;
      } else {
        var translateY = 'translateY(0)';
        elements[i].style.WebkitTransform = translateY;
        elements[i].style.msTransform = translateY;
        elements[i].style.transform = translateY;
        elements[i].style.visibility = 'visible';
        elements[i].style.opacity = 1;
      }
    }
      
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function() {
      for (var i = 0; i < elements.length; i++) {

      if((parentBoundaries.top + offset) < 0 && (parentBoundaries.bottom - (elementBoundaries.height)) > 0) {
          var translateY = 'translateY(' + Math.abs(Math.floor(parentBoundaries.top) + offset) + 'px)';
          elements[i].style.WebkitTransform = translateY;
          elements[i].style.msTransform = translateY;
          elements[i].style.transform = translateY;
        }
        elements[i].style.visibility = 'visible';
        elements[i].style.opacity = 1;
      }
    }, 250);     
  }
  
  
  // Use polyfill if postion: sticky; is not supported
  if (!positionStickySupported) {
    window.addEventListener("scroll", positionStickyPolyfill);
    window.addEventListener("resize", positionStickyPolyfill);
  }
  
}(typeof window !== "undefined" ? window : this, document));

//$(document).ready(function() {
//  
//  $('.table .table-header-group.new-sticky').each(function() {
//    var $clone = $(this)
//                  .clone()
//                  .removeClass('table-header-group new-sticky')
//                  .addClass('table-footer-group sticky-helper')
//                  .appendTo($(this).parent());
//  });
//  
//  $('.table .sticky-helper').each(function(){
//    var prevHeight = $(this).prev().height();
//    $(this).find('.table-row').css('transform', 'translateY(-' + prevHeight + 'px)'); 
//    
//  });
//  
//  
//});


;(function(window, document) {
  // Check if touch enabled device and add class to html
  var rootElement = document.documentElement;
  
  if (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
    rootElement.classList.add('touch');
  } else {
    rootElement.classList.add('no-touch');
  }
  
}(typeof window !== "undefined" ? window : this, document));

 
$(document).ready( function() {
      $(document).on('click', function(e) {
// Schließt offene Dropdown-Buttons beim Klick aufs document.
// Klick auf Label einer Checkbox feuert zwei Events! Eins für Checkbox, eins fürs Label
            _clickedElement = $(e.target);
            _$target = $(e.target);
            e.stopPropagation();
// Schließe alle Dropdown-Buttons wenn angeklickter Bereich NICHT das Label einer
// dropdown-Checkbox ist ODER eine Dropdown-Checkbox
        if ( !_$target.is('.dropdown > input[id$="-toggle"], .dropdown > label[for$="-toggle"], .dropdown > label[for$="-toggle"] *, .dropdown > .content, .dropdown > .content *, ' +
                          '.splitbutton > input[id$="-toggle"], .splitbutton > label[for$="-toggle"], .splitbutton > label[for$="-toggle"] *, .splitbutton > .content, .splitbutton > .content * ,' +
                          '.header-menu > input[id$="-toggle"], .header-menu > label[for$="-toggle"], .header-menu > label[for$="-toggle"] *, .header-menu > .content, .header-menu > .content *'
                         )) {              
              $('.dropdown > input[id$="-toggle"], .header-menu > input[id$="-toggle"], .splitbutton > input[id$="-toggle"]').prop('checked', false);
        } else if (!_$target.is('.dropdown > label[for$="-toggle"], .dropdown > label[for$="-toggle"] *, .dropdown > .content, .dropdown > .content *,' +
// Schließt alle anderen Dropdown-Checkboxen (aber nur beim Event des eigentlichen
// Inputs, nicht beim Label). Klar soweit?
                                '.splitbutton > label[for$="-toggle"], .splitbutton > label[for$="-toggle"] *, .splitbutton > .content, .splitbutton > .content *,' + 
                                '.header-menu > label[for$="-toggle"], .header-menu > label[for$="-toggle"] *, .header-menu > .content, .splitbutton > .content *'
                               ) ){
                  $('.dropdown > input[id$="-toggle"], .splitbutton > input[id$="-toggle"], .header-menu > input[id$="-toggle"]').not(_$target).prop('checked', false);
        }
      
            if (_$target.is('.header-menu [ng-click]') ){
                  $(_$target).closest('.header-menu').find('input[id$="-toggle"]').prop('checked', false);
            }   

            if (_$target.is('.dropdown [ng-click]') ){
                  $(_$target).closest('.dropdown').find('input[id$="-toggle"]').prop('checked', false);
            }   

                    if (_$target.is('.splitbutton [ng-click]') ){
                  $(_$target).closest('.splitbutton').find('input[id$="-toggle"]').prop('checked', false);
            }   

      });
  

  
// connect checkboxes with their labels
  
  $('input[type="checkbox"][id$="-toggle"], input[type="radio"][id$="-toggle"]').each(function() {
    if ($(this).attr('checked')) {
     $('label[for="' + $(this).attr('id') + '"]').addClass('active');
    }
  });
  
  
// Watch Checkboxes and Radios
jQuery.fn.watch = function( id, fn ) {
    return this.each(function(){
        var self = this;
 
        var oldVal = self[id];
        $(self).data(
            'watch_timer',
            setInterval(function(){
                if (self[id] !== oldVal) {
                    fn.call(self, id, oldVal, self[id]);
                    oldVal = self[id];
                }
            }, 100)
        );
    });
 
    return self;
};
     
      
$('input[type="checkbox"][id$="-toggle"], input[type="radio"][id$="-toggle"]').watch('checked', function(propName, oldVal, newVal){
  if(newVal) {
     $('label[for="' + $(this).attr('id') + '"]').addClass('active');
  } else {
     $('label[for="' + $(this).attr('id') + '"]').removeClass('active');
  } 
});      

});


